@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bottom-bar {
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #333333;
  color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

.bottom-bar__content {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.bottom-bar__content > * {
  display: flex;
  align-items: center;
}

.bottom-bar__text {
  padding-right: 10px;
}

.bottom-bar__close {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 2em;
}

.border-thin{
  border : 1px solid ;
  color: whitesmoke;
}

.border-thin-bottom{
  border-bottom : 2px solid ;
  color: whitesmoke;
}

.border-thin-right{
  border-right : 1px solid ;
  color: whitesmoke;
}

.logo-font {
  font-family: 'Sumana', serif;
}